import {UpdateGroupFarmDto} from "../../../models/domain/UpdateGroupFarmDto";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {useAuthContext} from "../../../hooks/useAuth";
import Constants from "../../../constants";
import axios from "axios";
import {ApiResourcePaths} from "../../../apiRoutes";
import {UpdateGroupDto} from "../../../models/domain/UpdateGroupDto";
import {FarmLinkKindDto} from "../../../models/domain/enums";

export type UseUpdateGroupFarmLinkButtonState = {
  saveMutation: UseMutationResult<UpdateGroupFarmDto, unknown, boolean>;
}

export const useUpdateGroupFarmLinkButton = (farm: UpdateGroupFarmDto, updateGroup: UpdateGroupDto | undefined): UseUpdateGroupFarmLinkButtonState => {
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();

  const saveMutation = useMutation({
    mutationFn: async (link: boolean): Promise<UpdateGroupFarmDto> => {
      const apiUrl = Constants.BACKEND_URL;

      if (!link) {
        return (await axios.delete(ApiResourcePaths.farmLinks(apiUrl, farm.farmId), {headers: authHeader})).data;
      }
      else {
        return (await axios.put(ApiResourcePaths.farmLinks(apiUrl, farm.farmId, FarmLinkKindDto.UpdateGroup, updateGroup?.id),
          null, {headers: authHeader})).data;
      }
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.allFarms(null)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms(null, farm.farmId)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farmLinks(null, farm.farmId)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroupFarms(null, updateGroup?.id)]
      });
    }
  });
  
  return {
    saveMutation
  }
}
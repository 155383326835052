import { ApiResourceTableColumnFactory } from "../../../hooks/useApiResourceTable";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { MrtUpdateStrategyField } from "../../mrt/mrt-update-strategy/MrtUpdateStrategyField";
import React from "react";

export const FarmLinkDialogUpdateGroupColumnsFactory: ApiResourceTableColumnFactory<UpdateGroupDto> = () => {
  return [
    {
      accessorKey: 'name',
      header: 'Update Group Name',
    },
    {
      accessorKey: 'updateStrategy',
      header: 'Update Strategy',
      Cell: ({cell}) => <MrtUpdateStrategyField cell={cell}/>
    },
    {
      accessorKey: "effectiveProductVersion.name",
      header: 'Effective version'
    },
  ];
}
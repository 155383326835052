import Constants from "../constants";
import axios from "axios";
import { ApiResourcePaths } from "../apiRoutes";
import { useAuthContext } from "./useAuth";
import { PageInfoDto } from "../models/domain/pagination/PageInfoDto";
import { UpdateGroupDto } from "../models/domain/UpdateGroupDto";
import {FarmLinkKindDto} from "../models/domain/enums";
import {UpdateGroupFarmDto} from "../models/domain/UpdateGroupFarmDto";

export type ApiResourceListParams<TExtraParams extends {} = {}> = {
  page: number;
  pageSize: number;
  filters: string;
  globalFilter: string;
  sorting: string;
} & TExtraParams;

export type IdExtraParams = {
  id: number | string;
}

export const useApiClient = () => {
  const apiUrl = Constants.BACKEND_URL;
  const { authHeader } = useAuthContext();

  const getProductVersionList = async (params: ApiResourceListParams) => {
    return (await axios.get(ApiResourcePaths.productVersions(apiUrl), {params, headers: authHeader})).data;
  }

  const getProductVersionFileList = async (params: ApiResourceListParams, idParams?: IdExtraParams) => {
    return (await axios.get(ApiResourcePaths.productVersionFiles(apiUrl, idParams?.id), {params, headers: authHeader})).data;
  }

  const getUpdateGroupList = async (params: ApiResourceListParams): Promise<PageInfoDto<UpdateGroupDto>> => {
    return (await axios.get(ApiResourcePaths.updateGroups(apiUrl), {params, headers: authHeader})).data;
  }

  const getUpdateGroupFarmList = async (params: ApiResourceListParams, idParams?: IdExtraParams) => {
    return (await axios.get(ApiResourcePaths.updateGroupFarms(apiUrl, idParams?.id), {params, headers: authHeader})).data;
  }

  const updateGroupFarmLinkUnlink = async (farmId: number, updateGroupId: number | null): Promise<UpdateGroupFarmDto> => {
    if (updateGroupId === null) {
      return (await axios.delete(ApiResourcePaths.farmLinks(apiUrl, farmId), {headers: authHeader})).data;
    }
    else {
      return (await axios.put(ApiResourcePaths.farmLinks(apiUrl, farmId, FarmLinkKindDto.UpdateGroup, updateGroupId),
        null, {headers: authHeader})).data;
    }
  }

  return {
    productVersion: {
      list: getProductVersionList
    },
    productVersionFile: {
      list: getProductVersionFileList
    },
    updateGroup: {
      list: getUpdateGroupList,
      farm: {
        list: getUpdateGroupFarmList,
        link: updateGroupFarmLinkUnlink
      }
    }
  }
}
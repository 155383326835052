import React from 'react';
import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { ApiResourceTable } from "../../../comps/api-resource-table/ApiResourceTable";
import { useFullFarmList } from "./useFullFarmList";

const FarmListPage = () => {
  const farmList = useFullFarmList();

  return (
    <Stack>
      <Toolbar>
        <Typography component="h1" variant="h5">
          Farms
        </Typography>
        <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }}/>
      </Toolbar>

      <Divider sx={{ mb: 2 }} />

      <ApiResourceTable {...farmList} />
    </Stack>
  );
}

export default FarmListPage;

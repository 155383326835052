import React from 'react';
import {
  Box, Divider, Paper, Toolbar, Typography,
} from "@mui/material";
import { ApiResourceTable, ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import {ProductVersionFileDto} from "../../../models/domain/ProductVersionFileDto";

export const ProductVersionFilesForm = (props: ApiResourceTableProps<ProductVersionFileDto>) => {
  return (
    <Paper elevation={2} sx={{mt: 2}}>
      <Box sx={{pt: 1, pb: 1}}>
        <Toolbar sx={{background: "inherit"}} variant="dense">
          <Typography component="h1" variant="h6" fontWeight="lighter" minWidth="230px">
            Product Version Files
          </Typography>
        </Toolbar>
      </Box>
      <Divider/>

      <Box sx={{mt: 1}}>
        <ApiResourceTable {...props} />
      </Box>
    </Paper>
  );
}

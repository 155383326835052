import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import farmsCardImage from "../../img/cards-farm.jpg";
import { LinkButton } from "../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../routes";

const SettingsPage = () => {
  return (
    <Grid container sx={{ml: 0}} spacing={4}>

      <Grid size={{ xs: 4 }}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={farmsCardImage}
            title="User Access"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              User Access
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Manage user roles and access to parts of portal
            </Typography>
          </CardContent>
          <CardActions>
            <LinkButton to={AdminAppRoutePaths.userAccess()}>
              Manage User Access
            </LinkButton>
          </CardActions>
        </Card>
      </Grid>

      <Grid size={{ xs: 4 }}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={farmsCardImage}
            title="Audit log"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Audit Log
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Review action taken on this portal
            </Typography>
          </CardContent>
          <CardActions>
            <LinkButton to={AdminAppRoutePaths.auditLog()}>
              Open Audit Log
            </LinkButton>
          </CardActions>
        </Card>
      </Grid>


      <Grid size={{ xs: 4 }}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={farmsCardImage}
            title="Manage Version Sources"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Version Sources
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Manually setup or correct version sources
            </Typography>
          </CardContent>
          <CardActions>
            <LinkButton to={AdminAppRoutePaths.versionSources()}>
              Open Version Sources
            </LinkButton>
          </CardActions>
        </Card>
      </Grid>

      <Grid size={{ xs: 4 }}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={farmsCardImage}
            title="Manage Version Source Hosts"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Version Source Hosts
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Setup version source hosts
            </Typography>
          </CardContent>
          <CardActions>
            <LinkButton to={AdminAppRoutePaths.versionSourceHosts()}>
              Open Version Source Hosts
            </LinkButton>
          </CardActions>
        </Card>
      </Grid>

    </Grid>
  );
}

export default SettingsPage;

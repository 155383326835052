import {
  FormControlLabel, FormGroup, Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { AppFormWithQuery, AppFormWithQueryProps } from "../../../core/form/AppForm";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { UpdateStrategySelect } from "../../../comps/fields/update-strategy-select/UpdateStrategySelect";
import { UpdateGroupProfileExtraProps } from "./useUpdateGroupProfile";

export const UpdateGroupProfileForm = (props: AppFormWithQueryProps<UpdateGroupDto, UpdateGroupDto, UpdateGroupProfileExtraProps>) => {
  const {primaryQuery: {data: initialValues}, isChangeAllowed} = props;

  return (
    <AppFormWithQuery {...props} initialValues={initialValues} formLabel="Update Group Profile">
      {({values, handleChange, handleBlur, touched, errors, setFieldValue}) => (
        <>
          <fieldset>
            <TextField
              id="name"
              name="name"
              label="Update Group Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <UpdateStrategySelect
              id="updateStrategy"
              name="updateStrategy"
              label="Update Strategy"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.updateStrategy}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.updateStrategy && Boolean(errors.updateStrategy)}
              helperText={touched.updateStrategy && errors.updateStrategy}
            />
          </fieldset>
          <fieldset>
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  id="name"
                  name="name"
                  disabled={!isChangeAllowed}
                  checked={values.isUpdateEnabled}
                  // value={values.isUpdateEnabled}
                  onChange={(e) => setFieldValue("isUpdateEnabled", e.target.checked)}
                  // onBlur={handleBlur}
                  // error={touched.name && Boolean(errors.name)}
                  // helperText={touched.name && errors.name}
                />
              } label="Update Delivery Enabled" />
            </FormGroup>
          </fieldset>
        </>
      )}
    </AppFormWithQuery>
  );
};
import React, { useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { MrtDateTimeField } from "../../../comps/mrt/mrt-datetime/MrtDateTimeField";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { MrtProductVersionStatusField } from "../../../comps/mrt/mrt-version-channel/MrtProductVersionStatusField";
import { useAuthContext } from "../../../hooks/useAuth";
import { ProductVersionStatusDto } from "../../../models/domain/enums";
import { Stack } from "@mui/material";
import { ProductVersionStatusButton } from "../../../comps/product-version-status-button/ProductVersionStatusButton";

const productVersionStatusSelectOptions = [
  { value: "Unassigned", text: "Unassigned" },
  { value: "Beta", text: "Beta" },
  { value: "Stable", text: "Stable" },
];

export const useProductVersionList = (): ApiResourceTableProps<ProductVersionDto> => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([{id: "pipelineBuildTimestamp", desc: true}]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {authHeader} = useAuthContext();

  const query = useQuery<PageInfoDto<ProductVersionDto>>({
      queryKey: [
        ApiResourcePaths.productVersions(),
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        const params = {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? [])
        };

        return (await axios.get(ApiResourcePaths.productVersions(apiUrl), {params, headers: authHeader})).data;
      },
      placeholderData: prev => prev,
    });

  const columns = useMemo<MRT_ColumnDef<ProductVersionDto>[]>(
    () => {
      return [
        {
          accessorKey: 'name',
          header: 'Product Version Name',
          Cell: ({renderedCellValue, row}) => <LinkButton
            to={AdminAppRoutePaths.productVersionId(row.original.id)}>{renderedCellValue}</LinkButton>
        },
        {
          accessorKey: 'productVersionStatus',
          header: 'Version Channel',
          Cell: ({cell}) => <>
            <Stack direction="row" alignItems={"center"}>
              <MrtProductVersionStatusField cell={cell}/>
              { cell.getValue<ProductVersionStatusDto>() === ProductVersionStatusDto.Unassigned &&
                <ProductVersionStatusButton productVersionId={cell.row.original.id} targetStatus={ProductVersionStatusDto.Beta} sx={{ml: 1}} color={"warning"}>To Beta</ProductVersionStatusButton>}
              { cell.getValue<ProductVersionStatusDto>() !== ProductVersionStatusDto.Stable &&
                <ProductVersionStatusButton productVersionId={cell.row.original.id} targetStatus={ProductVersionStatusDto.Stable} sx={{ml: 1}} color={"success"}>To Stable</ProductVersionStatusButton>}
            </Stack>
          </>,
          filterVariant: "select",
          filterSelectOptions: productVersionStatusSelectOptions,
        },
        {
          accessorKey: 'pipelineBuildTimestamp',
          header: 'Build Timestamp',
          Cell: ({cell}) => <MrtDateTimeField cell={cell}/>,
          filterVariant: "date-range"
        },
        {
          accessorKey: 'versionSourceId',
          header: 'Azure Folder',
          Cell: ({row}) => row.original.versionSource?.sourcePath || ""
        }
      ];
    },
    [],
  );

  return {
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
    query,
    columns,
    tableProps: {
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
    }
  };
}
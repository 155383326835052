import {ApiResourcePaths} from "../../../apiRoutes";
import {ProductVersionFileDto} from "../../../models/domain/ProductVersionFileDto";
import {ApiResourceTableState, useApiResourceTable} from "../../../hooks/useApiResourceTable";
import {IdExtraParams, useApiClient} from "../../../hooks/useApiClient";
import {ProductVersionFilesColumnsFactory} from "./ProductVersionFilesColumnsFactory";

export const useProductVersionFilesProfile = (id: number | undefined): ApiResourceTableState<ProductVersionFileDto> => {
  const apiClient = useApiClient();

  return {
      ...useApiResourceTable<ProductVersionFileDto, IdExtraParams>({
          requestFn: apiClient.productVersionFile.list,
          queryExtraParams: () => ({ id: !!id ? id : 0 }),
          queryKeyFn: () => ApiResourcePaths.productVersionFiles(null, id),
          columnFactory: ProductVersionFilesColumnsFactory,
          queryOptions: {
              enabled: !!id,
              placeholderData: prev => prev,
          },
          mrtParams: {
              initialPagination: {
                  pageIndex: 0,
                  pageSize: 15
              }
          }
      }),
      tableProps: {
          state: {
              density: "compact",
              pagination: {
                  pageIndex: 0,
                  pageSize: 15
              }
          },
          manualSorting: true,
          manualFiltering: true,
          manualPagination: true,
          muiPaginationProps: {
              rowsPerPageOptions: [15, 30, 60],
          }
      }
  };
}
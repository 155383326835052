import {
  TextField,
} from "@mui/material";
import React from "react";
import { FarmDto } from "../../../models/domain/FarmDto";
import { AppFormOnlyQuery, AppFormOnlyQueryProps } from "../../../core/form/AppFormOnlyQuery";
import { ProductBrandNames } from "../../../models/domain-enum-mapping";

export const FarmProfileForm = (props: AppFormOnlyQueryProps<FarmDto>) => {
  const {primaryQuery: {data: values}} = props;

  return (
    <AppFormOnlyQuery {...props} formLabel="Farm Profile">
      <fieldset>
        <TextField
          id="farmName"
          name="farmName"
          label="Farm Name"
          variant="standard"
          slotProps={{ input: { readOnly: true }}}
          value={values && values.farmName}
        />
        <TextField
          id="address"
          name="address"
          label="Address"
          variant="standard"
          slotProps={{ input: { readOnly: true }}}
          value={values && (values.countryCode + ", " + values.area)}
        />
      </fieldset>
      <fieldset>
        <TextField
          id="vendor"
          name="vendor"
          label="Vendor"
          variant="standard"
          slotProps={{ input: { readOnly: true }}}
          value={values && ProductBrandNames[values.vendor]}
        />
        <TextField
          id="dealer"
          name="dealer"
          label="Dealer"
          variant="standard"
          slotProps={{ input: { readOnly: true }}}
          value={values && values.dealer}
        />
      </fieldset>
    </AppFormOnlyQuery>
  );
};
import {
  Alert,
  Box, BoxProps, Button, CircularProgress,
  Collapse,
  Divider,
  LinearProgress,
  Paper, Snackbar,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import React from "react";
import { FormikConfig, FormikHelpers, useFormik } from "formik";
import { FormikProps } from "formik/dist/types";
import { defaultIfUndefined, isEmptyChildren, isFunction } from "../utils";
import { MutateOptions, UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { UseFormState } from "../../hooks/useForm";
import { useSnackbar } from "../../hooks/useSnackbar";

export type AppFormState = UseFormState;

export type AppFormIntrinsicProps<TVariables extends {}, TData = unknown, ExtraProps extends {} = {}> =
  AppFormState &
  {
    validationSchema?: any;
    mutation: UseMutationResult<TData, unknown, TVariables>;
    mutationOptions?: MutateOptions<TData, unknown, TVariables>

    initialValues?: TVariables | undefined;
    defaultValues: TVariables;

    resetOnCancel?: boolean;
    resetOnSuccess?: boolean;
    onSuccess?: (values: TVariables, data: TData) => void;
  } &
  ExtraProps;

export type AppFormQueryProps<TVariables extends {}> = {
  primaryQuery: UseQueryResult<TVariables>;
};

export type AppFormDerivedProps<T extends {}> =
  Pick<FormikConfig<T>, "innerRef" | "enableReinitialize"> &
  Pick<BoxProps, "sx">;

export type AppFormChildrenProps<TVariables extends {}, TData, ExtraProps extends {}> =
  FormikProps<TVariables> &
  Omit<AppFormIntrinsicProps<TVariables, TData, ExtraProps>, "initialValues">

export type AppFormBasicProps<TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}> =
  AppFormIntrinsicProps<TFormData, TResultData> &
  AppFormDerivedProps<TFormData> &
  {
    formLabel?: string;
    showError?: boolean;
    showFetching?: boolean;
    showNetworkError?: boolean;
    controls?: {
      showSave?: boolean,
      showCancel?: boolean,
      showEdit?: boolean,
      extra?: React.ReactNode;
    }
  } &
  ExtraProps;

export type AppFormProps<TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}> =
  AppFormBasicProps<TFormData, TResultData, ExtraProps> &
  {
    children?: ((props: AppFormChildrenProps<TFormData, TResultData, ExtraProps>) => React.ReactNode) | React.ReactNode;
  };

export type AppFormWithQueryProps<TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}> =
  AppFormBasicProps<TFormData, TResultData, ExtraProps> &
  AppFormQueryProps<TFormData> &
  {
    children?: ((props: AppFormChildrenProps<TFormData, TResultData, ExtraProps>) => React.ReactNode) | React.ReactNode;
  };

const BasicAppForm = <TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}>
(props: AppFormProps<TFormData, TResultData, ExtraProps> & {primaryQuery: AppFormQueryProps<TFormData>["primaryQuery"] | null}) => {
  const {
    primaryQuery,
    mutation,
    mutationOptions,
    validationSchema,
    initialValues,
    defaultValues,
    formLabel,
    showError,
    showFetching,
    showNetworkError = false,
    controls,
    children,
    innerRef,
    enableReinitialize = false,
    sx,
    resetOnCancel = true,
    resetOnSuccess = true,
    onSuccess,
    isChangeAllowed,
    isControlBlocked,
    setIsChangeAllowed
  } = props;
  const {mutateAsync, isPending: mutationPending, isError, error, reset} = mutation;

  const handleSubmit = React.useCallback(async (values: TFormData, helpers: FormikHelpers<TFormData>): Promise<TResultData> => {
    // alert(JSON.stringify(values, null, 2));
    const result = await mutateAsync(values, mutationOptions);

    if (onSuccess) {
      onSuccess(values, result);
    }

    if (resetOnSuccess) {
      helpers.resetForm();
    }

    return result;
  }, [mutateAsync, mutationOptions, onSuccess, resetOnSuccess]);

  const formik = useFormik<TFormData>({
    initialValues: initialValues || defaultValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize,
    innerRef,
  });

  const submit = React.useCallback(() => formik.submitForm(), [formik]);
  const cancelEditMode = React.useCallback(() => {
    setIsChangeAllowed(false);

    if (resetOnCancel) {
      formik.resetForm();
    }
  }, [setIsChangeAllowed, resetOnCancel, formik]);
  const enterEditMode = React.useCallback(() => setIsChangeAllowed(true), [setIsChangeAllowed]);

  const showEdit = defaultIfUndefined(controls?.showEdit, true);
  const showSave = defaultIfUndefined(controls?.showSave, true);
  const showCancel = defaultIfUndefined(controls?.showCancel, true);

  const {snackbarProps, setOpen} = useSnackbar({initialOpen: true, onClose: () => reset()});

  React.useEffect(() => {
    if (isError) {
      setOpen(true);
    }
  }, [isError, setOpen]);

  return (
    <Box sx={sx}>
      <Collapse in={showError && !!primaryQuery && primaryQuery.isError}>
        <Alert color="error" icon={false}>
          <Typography>Error occurred</Typography>
        </Alert>
      </Collapse>

      <Stack>
        {
          showFetching && !!primaryQuery && primaryQuery.isFetching ? <LinearProgress color="inherit"/> : null
        }

        <Paper elevation={2}>
          <Box
            sx={{
              '& .MuiTextField-root': {m: 1, width: '50ch'},
              '& .MuiFormControl-root': {m: 1, width: '50ch'},
              '& fieldset': {border: 0},
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box sx={{pt: 1, pb: 1}}>
                <Toolbar sx={{background: "inherit"}} variant="dense">
                  <Typography component="h1" variant="h6" fontWeight="lighter" minWidth="230px">
                    {formLabel}
                  </Typography>
                  {(showSave || showCancel || showEdit || controls?.extra) && <Divider orientation="vertical" flexItem sx={{ml: 2, mr: 2}}/>}

                  {
                    isChangeAllowed ? <>
                      {showSave && <Button variant="contained" onClick={submit} disabled={isControlBlocked}>
                        <Typography>Save</Typography>
                        {mutationPending && <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/>}
                      </Button>}
                      {showCancel && <Button variant="outlined" color="info" disabled={isControlBlocked}
                                             onClick={cancelEditMode}
                                             sx={{ml: 1}}>
                        <Typography>Cancel</Typography>
                      </Button>}
                    </> : <>
                      {showEdit && <Button variant="outlined" onClick={enterEditMode}>
                        <Typography>Edit</Typography>
                      </Button>
                      }
                    </>
                  }
                  {controls?.extra}
                </Toolbar>
              </Box>
              <Divider/>

              {
                isFunction(children)
                  ? (children as (childrenProps: AppFormChildrenProps<TFormData, TResultData, ExtraProps>) => React.ReactNode)(
                    {...formik, ...props}
                  )
                  : !isEmptyChildren(children)
                    ? React.Children.only(children)
                    : null
              }
            </form>
          </Box>
        </Paper>
      </Stack>
      {isError && <Snackbar
        {...snackbarProps}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        autoHideDuration={5000}
      >
        <Alert onClose={snackbarProps.onClose} severity="error">
          {showNetworkError ? error as string : "Error occurred"}
        </Alert>
      </Snackbar>}
    </Box>
  );
};

export const AppFormWithQuery = <TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}>
(props: AppFormWithQueryProps<TFormData, TResultData, ExtraProps>) => (<BasicAppForm {...props} />);

export const AppForm = <TFormData extends {}, TResultData = unknown, ExtraProps extends {} = {}>
(props: AppFormProps<TFormData, TResultData, ExtraProps>) => (<BasicAppForm {...props} primaryQuery={null}/>);
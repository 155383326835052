import React, { useMemo, useState } from "react";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { PageInfoDto } from "../../../models/domain/pagination/PageInfoDto";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { MrtDateTimeField } from "../../../comps/mrt/mrt-datetime/MrtDateTimeField";
import { LinkButton } from "../../../comps/link-button/LinkButton";
import { AdminAppRoutePaths } from "../../../routes";
import { ApiResourceTableProps } from "../../../comps/api-resource-table/ApiResourceTable";
import { MrtUpdateStrategyField } from "../../../comps/mrt/mrt-update-strategy/MrtUpdateStrategyField";
import { MrtYesNoField } from "../../../comps/mrt/mrt-yes-no/MrtYesNoField";
import { useAuthContext } from "../../../hooks/useAuth";
import { UpdateGroupListItemDto } from "../../../models/domain/UpdateGroupListItemDto";

export const useUpdateGroupList = (): ApiResourceTableProps<UpdateGroupListItemDto> => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {authHeader} = useAuthContext();

  const query = useQuery<PageInfoDto<UpdateGroupListItemDto>>({
      queryKey: [
        ApiResourcePaths.updateGroups(),
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        const params = {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? [])
        };

        return (await axios.get(ApiResourcePaths.updateGroups(apiUrl), {params, headers:authHeader})).data;
      },
      placeholderData: prev => prev,
    });

  const columns = useMemo<MRT_ColumnDef<UpdateGroupListItemDto>[]>(
    () => {
      return [
        {
          accessorKey: 'name',
          header: 'Update Group Name',
          Cell: ({renderedCellValue, row}) => <LinkButton
            to={AdminAppRoutePaths.updateGroupId(row.original.id)}>{renderedCellValue}</LinkButton>
        },
        {
          accessorKey: "isUpdateEnabled",
          header: 'Enabled',
          Cell: ({cell}) => <MrtYesNoField cell={cell}/>
        },
        {
          accessorKey: 'updateStrategy',
          header: 'Update Strategy',
          Cell: ({cell}) => <MrtUpdateStrategyField cell={cell}/>
        },
        {
          accessorKey: "effectiveProductVersion.name",
          header: 'Effective version',
        },
        {
          accessorKey: "farmsCount",
          header: 'Farms Count',
        },
        {
          accessorKey: 'createdAt',
          header: 'Created',
          Cell: ({cell}) => <MrtDateTimeField cell={cell}/>
        },
        {
          accessorKey: 'updatedAt',
          header: 'Updated',
          Cell: ({cell}) => <MrtDateTimeField cell={cell}/>
        },
      ];
    },
    [],
  );

  return {
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
    query,
    columns
  };
}
import React from "react";
import { MRT_Updater } from "material-react-table";
import { AuthHeader } from "../models/auth";

export type StateChangeFn<T> = (updaterOrValue: MRT_Updater<T> | T) => void;

export const isFunction = (obj: any): obj is Function =>
  typeof obj === 'function';

export const isEmptyChildren = (children: any): boolean =>
  React.Children.count(children) === 0;

export const defaultIfUndefined = <T>(value: T | undefined, defaultValue: T): T =>
  typeof value === "undefined" ? defaultValue : value

export const makeAuthHeader = (token?: string | null): AuthHeader => ({
  Authorization: token ? `ENGS-USER ${token}` : ""
})

export const getSelectedRowId = (obj: object) : number | null => {
  const keys = Object.keys(obj);
  if (keys.length === 0) return null;

  return parseInt(keys[0]) || null;
}
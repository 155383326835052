import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import { useVersionSourceHostProfile } from "./useVersionSourceHostProfile";
import { useParams } from "react-router-dom";
import { VersionSourceHostProfileForm } from "./VersionSourceHostProfileForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ArrowBack, Delete } from "@mui/icons-material";
import { VersionSourceHostIcon } from "../../../comps/icons/VersionSourceHostIcon";
import { AdminAppRoutePaths } from "../../../routes";
import { LinkButton } from "../../../comps/link-button/LinkButton";

const VersionSourceHostItemPage = () => {
  const {versionSourceHostId} = useParams();
  const versionSourceHostProfile = useVersionSourceHostProfile(parseInt(versionSourceHostId || ''));
  const {data, isFetching, isLoading, refetch} = versionSourceHostProfile.primaryQuery;

  const onRefetch = React.useCallback(() => refetch(), [refetch]);

  return (
    <Stack>
      <Box sx={{
        '& .MuiToolbar-root': {pl: 0, pr: 0},
      }}>
        <Toolbar variant="dense">
          <LinkButton variant="text" to={AdminAppRoutePaths.versionSourceHosts()} sx={{ml: 0}}>
            <ArrowBack/>
          </LinkButton>

          <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

          <Typography sx={{ mr: 1 }}>
            <VersionSourceHostIcon fontSize="large"/>
          </Typography>
          {
            isLoading
              ? <CircularProgress/>
              : <Typography component="h1" variant="h5" color="inherit">
                {data?.name}
              </Typography>
          }

          <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

          <Button variant="outlined" onClick={onRefetch} >
            <Typography>Refresh</Typography>
            { isFetching ? <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/> : <RefreshIcon/>}
          </Button>

          <Button variant="outlined" color="warning" sx={{ml: 1}}
                  disabled={isFetching || versionSourceHostProfile.isChangeAllowed}>
            <Typography>Delete</Typography>
            <Delete/>
          </Button>
        </Toolbar>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {
        isLoading || !data
          ? <Skeleton/>
          : <>
            <VersionSourceHostProfileForm {...versionSourceHostProfile}/>
          </>
      }

    </Stack>
  );
}

export default VersionSourceHostItemPage;

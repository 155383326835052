import React from 'react';
import { Button, CircularProgress, Divider, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import { useFarmProfile } from "./useFarmProfile";
import { useParams } from "react-router-dom";
import { FarmProfileForm } from "./FarmProfileForm";
import { FarmIcon } from "../../../comps/icons/FarmIcon";
import { FarmUpdateGroupForm } from "./FarmUpdateGroupForm";
import { useFarmUpdateGroupProfile } from "./useFarmUpdateGroupProfile";
import RefreshIcon from "@mui/icons-material/Refresh";

const FarmItemPage = () => {
  const {farmId} = useParams();
  const farmProfile = useFarmProfile(farmId || '');
  const {data, isFetching, isLoading, refetch} = farmProfile.primaryQuery;

  const farmUpdateGroupProfile = useFarmUpdateGroupProfile(data);

  const onRefetch = React.useCallback(() => refetch(), [refetch]);

  return (
    <Stack>
      <Toolbar variant="dense">
        <Typography sx={{ mr: 1 }}>
          <FarmIcon fontSize="large"/>
        </Typography>
        {
          isLoading
            ? <CircularProgress/>
            : <Typography component="h1" variant="h5" color="inherit">
                {data?.farmName}
              </Typography>
        }

        <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} flexItem />

        <Button variant="outlined" onClick={onRefetch} >
          <Typography>Refresh</Typography>
          { isFetching ? <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/> : <RefreshIcon/>}
        </Button>
      </Toolbar>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {
        isLoading || !data
          ? <Skeleton/>
          : <>
            <FarmProfileForm {...farmProfile}/>
            <FarmUpdateGroupForm {...farmUpdateGroupProfile} sx={{ mt: 2 }}/>
          </>
      }

    </Stack>
  );
}

export default FarmItemPage;

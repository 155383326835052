import React from 'react';
import logo from "../../logo.svg";
import { Outlet } from "react-router-dom";
import { RootNav } from "./RootNav";
import { AppBar, Box, Toolbar, IconButton, Typography, styled, Stack } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useAuthContext } from "../../hooks/useAuth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enGB } from "date-fns/locale";

const drawerWidth = 240;

const logoSize = 32;
const StyledLogoImg = styled('img')(`
  width: ${logoSize}px;
  height: ${logoSize}px;
`);

function Root() {
  const {user} = useAuthContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Box sx={{display: 'flex'}}>
        <Stack sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <Box>
                <StyledLogoImg src={logo} alt="ENGS logo" sx={{ mr: 2 }}/>
              </Box>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
              >
                EcoHerd Auto-Update
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              {/*<IconButton color="inherit">*/}
              {/*  <Badge badgeContent={4} color="secondary">*/}
              {/*    <NotificationsIcon />*/}
              {/*  </Badge>*/}
              {/*</IconButton>*/}

              <IconButton color="inherit">

                {!!user && <Typography>
                  {user.firstName + " " + user.familyName}
                </Typography>}

                <AccountCircle sx={{ml:1}}/>
              </IconButton>

            </Toolbar>
          </AppBar>

          <Stack direction="row">
            <RootNav drawerWidth={drawerWidth} />

            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100%',
                //overflow: 'auto',
              }}
            >
              <Toolbar/>
              <Box sx={{ mt: 2, mb: 4, ml: 2, mr: 2 }}>
                <Outlet/>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}

export default Root;

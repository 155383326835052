import React from "react";
import {
  Box,
  Button, CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { AppDialog } from "../../dialogs/dialog/AppDialog";
import { DialogOutcome } from "../../../models/dialog";
import { FarmLinkDialogExtraProps, FarmLinkDialogProps, FarmLinkDialogValue } from "./useFarmLinkDialog";
import { FarmLinkKindDto } from "../../../models/domain/enums";
import { ApiResourceTable } from "../../api-resource-table/ApiResourceTable";

export const FarmLinkDialog = (props: FarmLinkDialogProps) => {
  const {handleClose, saveMutation, farm, isSaveEnabled} = props;
  const {switchSourceToNone, switchSourceToUpdateGroup, switchSourceToProductVersion, resetDialogValue} = props;
  const {isPending} = saveMutation;

  const handleCancel = () => {
    handleClose(DialogOutcome.Cancel);
  };

  const handleOk = () => {
    handleClose(DialogOutcome.Ok);
  };

  const handleSourceChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const sourceValue = (event.target as HTMLInputElement).value;

    if (sourceValue === "ug") {
      switchSourceToUpdateGroup();
    }
    else if (sourceValue === "pv") {
      switchSourceToProductVersion();
    }
    else {
      switchSourceToNone();
    }
  }, [switchSourceToNone, switchSourceToProductVersion, switchSourceToUpdateGroup]);

  return (
    <AppDialog<FarmLinkDialogValue, FarmLinkDialogExtraProps>
      {...props}
      sx={{ '& .MuiDialog-paper': { width: "100%", maxHeight: 900 } }}
      maxWidth="md"
      dividers
      keepMounted={false}

      title={`Set farm update source - ${farm.farmId}`}
      actions={
        <>
          <Button autoFocus onClick={handleCancel} disabled={isPending}>
            Cancel
          </Button>
          <Button onClick={handleOk} disabled={!isSaveEnabled || isPending}>Ok</Button>
          {isPending && <CircularProgress size="20px" color="inherit" sx={{ml: 1}}/>}
        </>
      }
    >
      {({value}) => (
        <>
          <Typography>Farm current EcoHerd version: {farm.ecoHerdVersion ?? "N/A"}</Typography>

          <Divider sx={{mt:2, mb:2}}/>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
            <FormControl>
              <FormLabel id="farm-update-source-radio-group">Update source</FormLabel>
              <RadioGroup
                aria-labelledby="farm-update-source-radio-group"
                row
                value={!value ? "none" : value.linkKind === FarmLinkKindDto.UpdateGroup ? "ug" : "pv"}
                onChange={handleSourceChange}
              >
                <FormControlLabel value="none" control={<Radio />} label="None" />
                <FormControlLabel value="ug" control={<Radio />} label="Update Group" />
                <FormControlLabel value="pv" control={<Radio />} label="Specific Version" />
              </RadioGroup>
            </FormControl>

            <FormControl>
              <Button onClick={resetDialogValue} disabled={isPending}>Reset Form</Button>
            </FormControl>
          </Box>

          <Divider/>

          <Box sx={{mt: 2}}>
            {value === null && <Typography>
              Farm will be set to not receive any updates.
            </Typography>}

            {value?.linkKind === FarmLinkKindDto.UpdateGroup &&
              <ApiResourceTable {...props.updateGroupsTableProps}/>
            }

            {value?.linkKind === FarmLinkKindDto.ProductVersion &&
              <ApiResourceTable {...props.productVersionsTableProps}/>
            }
          </Box>
        </>
      )}
    </AppDialog>
  );
}

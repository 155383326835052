import {
  Box,
  Button, CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { AppForm, AppFormProps } from "../../core/form/AppForm";
import { LoginFormData } from "./useLogin";
import { AuthSignInResponse } from "../../hooks/useAuth";

export const LoginForm = (props: AppFormProps<LoginFormData, AuthSignInResponse | null>) => {
  const {isChangeAllowed, initialValues, mutation: { isPending }} = props;

  return (
    <AppForm {...props} initialValues={initialValues} formLabel="Login" controls={{showEdit: false, showSave: false, showCancel: false}}>
      {({values, handleChange, handleBlur, touched, errors}) => (
        <>
          <fieldset>
            <TextField
              id="username"
              name="username"
              label="User Name"
              variant="standard"
              disabled={!isChangeAllowed}
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
            />
          </fieldset>
          <fieldset>
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="standard"
              type="password"
              disabled={!isChangeAllowed}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
          </fieldset>
          <fieldset >
            <Box display="flex" alignItems="center">
              <Button variant="contained" type="submit" disabled={isPending || !isChangeAllowed}>Login</Button>
              {isPending && <CircularProgress size="20px" sx={{ml: 1}}/>}
            </Box>
          </fieldset>
        </>
      )}
    </AppForm>
  );
};
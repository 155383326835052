import { ApiResourceTableColumnFactory } from "../../../hooks/useApiResourceTable";
import React from "react";
import {ProductVersionFileDto} from "../../../models/domain/ProductVersionFileDto";
import {
  MrtProductVersionFileKeyField
} from "../../../comps/mrt/mrt-product-version-file-key/MrtProductVersionFileKeyField";
import {MrtFileTargetBrandField} from "../../../comps/mrt/mrt-file-target-brand/MrtFileTargetBrandField";
import {MrtDownloadButtonField} from "../../../comps/mrt/mrt-download-button/MrtDownloadButtonField";
import {
  fileTargetBrandSelectOptions,
  productVersionFileKeySelectOptions
} from "../../updateGroups/item/UpdateGroupFarmsColumnsFactory";

export const ProductVersionFilesColumnsFactory: ApiResourceTableColumnFactory<ProductVersionFileDto> = () => {
  return [
    {
      accessorKey: 'path',
      header: 'Name',
    },
    {
      accessorKey: "key",
      header: 'File Type',
      Cell: ({cell}) => <MrtProductVersionFileKeyField cell={cell}/>,
      filterVariant: "select",
      filterSelectOptions: productVersionFileKeySelectOptions,
    },
    {
      accessorKey: "targetBrand",
      header: 'Vendor',
      Cell: ({cell}) => <MrtFileTargetBrandField cell={cell}/>,
      filterVariant: "select",
      filterSelectOptions: fileTargetBrandSelectOptions,
    },
    {
      accessorKey: "downloadUrl",
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({cell}) => <MrtDownloadButtonField cell={cell} fileName={cell.row.original.path}/>
    },
  ];
}
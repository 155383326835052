import React from "react";
import { MaterialReactTable } from "material-react-table";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ApiResourceTableState } from "../../hooks/useApiResourceTable";

export type ApiResourceTableProps<T extends Record<string, any>> = ApiResourceTableState<T>;

export const ApiResourceTable = <T extends Record<string, any>,>(
  {
    columnFilters, setColumnFilters,
    globalFilter, setGlobalFilter,
    sorting, setSorting,
    pagination, setPagination,
    overrideData,
    query,
    columns,
    tableProps,
    muiProps
  }: ApiResourceTableProps<T>) => {
  const {state, ...restTableProps} = tableProps ?? {};
  const {data, isError, isFetching, isLoading, refetch} = query;

  const resultData = overrideData?.items ?? data?.items ?? [];
  const resultDataCount = overrideData?.totalCount ?? data?.totalCount ?? 0;

  return (
    <MaterialReactTable
      columns={columns}
      data={resultData}
      initialState={{ showColumnFilters: false, }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
            color: 'error',
            children: 'Error loading data',
          }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderTopToolbarCustomActions={() => (
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      rowCount={resultDataCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
        ...state
      }}
      {...muiProps}
      {...restTableProps}
    />
  );
};
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../../../hooks/useAuth";
import { FarmLinkKindDto } from "../../../models/domain/enums";
import Constants from "../../../constants";
import { ApiResourcePaths } from "../../../apiRoutes";
import React from "react";
import { FarmDto } from "../../../models/domain/FarmDto";
import { AppDialogControlState, useAppDialog } from "../../dialogs/dialog/useAppDialog";
import { DialogOutcome } from "../../../models/dialog";
import axios from "axios";
import { AppDialogProps } from "../../dialogs/dialog/AppDialog";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { ApiResourceTableState, useApiResourceTable } from "../../../hooks/useApiResourceTable";
import { useApiClient } from "../../../hooks/useApiClient";
import { FarmLinkDialogUpdateGroupColumnsFactory } from "./FarmLinkDialogUpdateGroupColumnsFactory";
import { ProductVersionDto } from "../../../models/domain/ProductVersionDto";
import { FarmLinkDialogProductVersionColumnsFactory } from "./FarmLinkDialogProductVersionColumnsFactory";
import { getSelectedRowId } from "../../../core/utils";

export type FarmLinkDialogValue = {
  linkKind: FarmLinkKindDto;
  linkId: number;
} | null;

export type FarmLinkDialogExtraProps = {
  switchSourceToNone: () => void;
  switchSourceToUpdateGroup: () => void;
  switchSourceToProductVersion: () => void;
  resetDialogValue: () => void;
  isSaveEnabled: boolean;
  farm: FarmDto;
  saveMutation:  UseMutationResult<FarmDto, unknown, FarmLinkDialogValue>;
  updateGroupsTableProps: ApiResourceTableState<UpdateGroupDto>;
  productVersionsTableProps: ApiResourceTableState<ProductVersionDto>;
}

export type FarmLinkDialogProps = AppDialogProps<FarmLinkDialogValue, FarmLinkDialogExtraProps>;

export const useFarmLinkDialog = (farm: FarmDto): FarmLinkDialogProps => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const {authHeader} = useAuthContext();

  const saveMutation = useMutation({
    mutationFn: async (value: FarmLinkDialogValue): Promise<FarmDto> => {
      const apiUrl = Constants.BACKEND_URL;

      if (value === null) {
        return (await axios.delete(ApiResourcePaths.farmLinks(apiUrl, farm.farmId), {headers: authHeader})).data;
      }
      else {
        return (await axios.put(ApiResourcePaths.farmLinks(apiUrl, farm.farmId, value.linkKind, value.linkId),
          null, {headers: authHeader})).data;
      }
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.allFarms(null)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farms(null, farm.farmId)]
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.farmLinks(null, farm.farmId)]
      });
    }
  });

  const onClose = React.useCallback(async (state: AppDialogControlState, outcome: DialogOutcome, value: FarmLinkDialogValue | undefined) => {
    if (outcome === DialogOutcome.Ok && typeof value !== "undefined") {
      await saveMutation.mutateAsync(value);
    }

    if (outcome !== DialogOutcome.Undefined) {
      state.setOpen(false);
    }
  }, [saveMutation]);


  // ===== dialog props and value =====
  const dialogProps = useAppDialog<FarmLinkDialogValue>({
    onClose: onClose
  })

  const {value, setValue} = dialogProps;
  const [isSaveEnabled, setIsSaveEnabled] = React.useState(false);

  const setSourceNone = React.useCallback(() => {
    setValue(null);
    setIsSaveEnabled(true);
  }, [setValue, setIsSaveEnabled]);

  const setSourceUpdateGroup = React.useCallback((linkId: number) => {
    setValue({ linkKind: FarmLinkKindDto.UpdateGroup, linkId });
    setIsSaveEnabled(Boolean(linkId));
  }, [setValue, setIsSaveEnabled]);

  const setSourceProductVersion = React.useCallback((linkId: number) => {
    setValue({ linkKind: FarmLinkKindDto.ProductVersion, linkId });
    setIsSaveEnabled(Boolean(linkId));
  }, [setValue, setIsSaveEnabled]);

  // ===== update group table =====
  const [updateGroupRowSelection, setUpdateGroupRowSelection] = React.useState({});

  const setSelectedUpdateGroup = React.useCallback((updateGroupId: number | null) => {
    if (updateGroupId == null) {
      setUpdateGroupRowSelection({});
    }
    else {
      setUpdateGroupRowSelection({[updateGroupId]: true});
    }
  }, []);

  React.useEffect(() => {
    const numericKey = getSelectedRowId(updateGroupRowSelection);
    if (numericKey === null) return;

    setSourceUpdateGroup(numericKey);
  }, [setSourceUpdateGroup, updateGroupRowSelection]);

  const updateGroupsTableProps: ApiResourceTableState<UpdateGroupDto> = {
    ...useApiResourceTable({
      requestFn: apiClient.updateGroup.list,
      queryKeyFn: ApiResourcePaths.updateGroups,
      columnFactory: FarmLinkDialogUpdateGroupColumnsFactory,
      queryOptions: {
        enabled: (value && value.linkKind === FarmLinkKindDto.UpdateGroup) ?? false,
        placeholderData: prev => prev,
      }
    }),
    tableProps: {
      enableRowSelection: true,
      enableMultiRowSelection: false,
      onRowSelectionChange: setUpdateGroupRowSelection,
      getRowId: r => r?.id?.toString(),
      state: {
        rowSelection: updateGroupRowSelection,
        density: "compact"
      },
      positionToolbarAlertBanner: "none"
    }
  }

  // ===== product version table =====
  const [productVersionRowSelection, setProductVersionRowSelection] = React.useState({});

  const setSelectedProductVersion = React.useCallback((productVersionId: number | null) => {
    if (productVersionId == null) {
      setProductVersionRowSelection({});
    }
    else {
      setProductVersionRowSelection({[productVersionId]: true});
    }
  }, []);

  React.useEffect(() => {
    const numericKey = getSelectedRowId(productVersionRowSelection);
    if (numericKey === null) return;

    setSourceProductVersion(numericKey);
  }, [productVersionRowSelection, setSourceProductVersion]);

  const productVersionsTableProps: ApiResourceTableState<ProductVersionDto> = {
    ...useApiResourceTable({
      requestFn: apiClient.productVersion.list,
      queryKeyFn: ApiResourcePaths.productVersions,
      columnFactory: FarmLinkDialogProductVersionColumnsFactory,
      queryOptions: {
        enabled: (value && value.linkKind === FarmLinkKindDto.ProductVersion) || false,
        placeholderData: prev => prev,
      },
      mrtParams: {
        initialSorting: [{id: "pipelineBuildTimestamp", desc: true}]
      }
    }),
    tableProps: {
      enableRowSelection: true,
      enableMultiRowSelection: false,
      onRowSelectionChange: setProductVersionRowSelection,
      getRowId: r => r?.id?.toString(),
      state: {
        rowSelection: productVersionRowSelection,
        density: "compact"
      },
      positionToolbarAlertBanner: "none"
    }
  }

  // ===== switching dialog state =====
  const switchSourceToNone = React.useCallback(() => {
    setSourceNone();
  }, [setSourceNone]);

  const switchSourceToUpdateGroup = React.useCallback(() => {
    const lastSelectedId = getSelectedRowId(updateGroupRowSelection);
    setSourceUpdateGroup(lastSelectedId ?? 0);
  }, [setSourceUpdateGroup, updateGroupRowSelection]);

  const switchSourceToProductVersion = React.useCallback(() => {
    const lastSelectedId = getSelectedRowId(productVersionRowSelection);
    setSourceProductVersion(lastSelectedId ?? 0);
  }, [productVersionRowSelection, setSourceProductVersion]);

  const resetDialogValue = React.useCallback(() => {
    if (farm.productVersionId == null && farm.updateGroupId == null) {
      setSourceNone();
      setSelectedUpdateGroup(null);
      setSelectedProductVersion(null);
    }
    else if (farm.productVersionId !== null) {
      setSourceProductVersion(farm.productVersionId);
      setSelectedProductVersion(farm.productVersionId);
      setSelectedUpdateGroup(null);
    }
    else if (farm.updateGroupId !== null) {
      setSourceUpdateGroup(farm.updateGroupId);
      setSelectedUpdateGroup(farm.updateGroupId);
      setSelectedProductVersion(null);
    }
  }, [farm.productVersionId, farm.updateGroupId, setSelectedProductVersion, setSelectedUpdateGroup, setSourceNone, setSourceProductVersion, setSourceUpdateGroup]);

  // ===== on dialog init load current farm state =====
  React.useEffect(() => {
    resetDialogValue();
  }, [resetDialogValue]);

  // ===== form dialog state =====
  return {
    ...dialogProps,

    switchSourceToNone,
    switchSourceToUpdateGroup,
    switchSourceToProductVersion,
    resetDialogValue,

    setValue: () => { throw new Error("direct state change not allowed"); },

    isSaveEnabled,
    farm,
    saveMutation,
    updateGroupsTableProps,
    productVersionsTableProps
  }
}
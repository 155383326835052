import { useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResourcePaths } from "../../../apiRoutes";
import Constants from "../../../constants";
import axios from "axios";
import { useForm } from "../../../hooks/useForm";
import * as yup from "yup";
import { AppFormWithQueryProps } from "../../../core/form/AppForm";
import { UpdateGroupDto } from "../../../models/domain/UpdateGroupDto";
import { UpdateStrategyDto } from "../../../models/domain/enums";
import { enumType } from "../../../core/validation/helpers";
import { useAuthContext } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { AdminAppRoutePaths } from "../../../routes";

const validationSchema = yup.object().shape({
  name: yup.string().required("Update group name is required"),
  updateStrategy: enumType(UpdateStrategyDto).required("Update strategy is required"),
  isUpdateEnabled: yup.boolean().required()
})

export interface UpdateGroupProfileExtraProps {
  deleteMutation: UseMutationResult<UpdateGroupDto, unknown, void>;
}

export const useUpdateGroupProfile = (id: number): AppFormWithQueryProps<UpdateGroupDto, UpdateGroupDto, UpdateGroupProfileExtraProps> => {
  const formState = useForm();
  const queryClient = useQueryClient();
  const {authHeader} = useAuthContext();
  const navigate = useNavigate();

  const primaryQuery = useQuery<UpdateGroupDto>({
      queryKey: [
        ApiResourcePaths.updateGroups(null, id),
      ],
      queryFn: async () => {
        const apiUrl = Constants.BACKEND_URL;
        return (await axios.get(ApiResourcePaths.updateGroups(apiUrl, id), {headers:authHeader})).data;
      },
    });

  const mutation = useMutation({
    mutationFn: async (values: UpdateGroupDto): Promise<UpdateGroupDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.patch(ApiResourcePaths.updateGroups(apiUrl, id), values, {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups()],
        exact: true
      });
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups(null, id)]
      });

      formState.setIsChangeAllowed(false);
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: async (): Promise<UpdateGroupDto> => {
      const apiUrl = Constants.BACKEND_URL;
      return (await axios.delete(ApiResourcePaths.updateGroups(apiUrl, id), {headers:authHeader})).data;
    },
    onMutate: _ => {
      formState.setIsControlBlocked(true);
    },
    onSuccess: _ => {
      queryClient.invalidateQueries({
        queryKey: [ApiResourcePaths.updateGroups()],
        exact: true
      });

      formState.setIsChangeAllowed(false);

      navigate(AdminAppRoutePaths.updateGroups());
    },
    onSettled: _ => {
      formState.setIsControlBlocked(false);
    }
  });

  return {
    ...formState,
    primaryQuery,
    mutation,
    deleteMutation,
    validationSchema,
    initialValues: primaryQuery.data,
    defaultValues: {
      id: -1,
      name: "",
      isUpdateEnabled: false,
      updateStrategy: UpdateStrategyDto.LatestStable
    }
  };
}